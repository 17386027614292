<template>
  <div>
    <div class="loading-spinner" v-if="loading">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <VerifyProfile
        :verifyStep="verifyStep"
        :checkCompliance="investorProfile.ProfileDone"
        :checkComplyCube="checkComplyCube"
        @handleCompleteComplyCubeRegister="handleCompleteComplyCubeRegister"
      />
    </div>
  </div>
</template>

<script>
import VerifyProfile from "./components/VerifyProfile";
import ApiService from "@/services/ApiService";
import { verifyProfileEnums } from "../enums/ProfileEnum";

export default {
  components: {
    VerifyProfile,
  },
  name: "CompleteProfileVerifySteps",
  data() {
    return {
      investorProfile: null,
      loading: false,
      apiService: new ApiService(),
      verifyStep: null,
      verifyProfileEnums,
      checkComplyCube: false,
    };
  },
  async created() {
    await this.getProfile();
    await this.getComplyCubePendingIssues();
  },
  methods: {
    async handleCompleteComplyCubeRegister() {
      await this.getComplyCubePendingIssues();
    },
    async getComplyCubePendingIssues() {
      this.loading = true;

      const user = JSON.parse(localStorage.getItem("user"));

      await this.apiService
        .getRequest(`backgroundcheck/checks-pending/${user.id}`)
        .then((resp) => {
          const complyCubeChecks = resp.content;

          if (complyCubeChecks && complyCubeChecks.length > 0) {
            this.checkComplyCube = false;
          } else {
            this.checkComplyCube = true;
          }

          this.checkVerifyStatus();
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });

      this.loading = false;
    },
    async getProfile() {
      this.loading = true;
      await this.apiService
        .getRequest("user/profile")
        .then((resp) => {
          this.investorProfile = JSON.parse(resp);
          this.checkVerifyStatus();
        })
        .catch((error) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
      this.loading = false;
    },
    checkVerifyStatus() {
      if (this.investorProfile.ProfileDone && this.checkComplyCube) {
        this.verifyStep = this.verifyProfileEnums.SUCCESSFULLY_VERIFIED;
      } else {
        this.verifyStep = this.verifyProfileEnums.VERIFY_ACCOUNT;
      }
    },
  },
};
</script>

<style scoped>
.loading-spinner {
  display: grid;
  place-items: center;
  margin-top: 120px;
}
</style>
