<template>
  <v-container style="min-width: 100%; padding-left: 0px">
    <div>
      <font-awesome-icon
        icon="fa-solid fa-arrow-left"
        class="arrow-back"
        @click="handleInvestorRedirectAfterCompleteProfile()"
      />
      <div class="verify-title-container mobile-padding">
        <div
          class="icon-check-profile-container"
          v-if="verifyStep === verifyProfileEnums.SUCCESSFULLY_VERIFIED"
        >
          <font-awesome-icon
            icon="fa-solid fa-user-check"
            class="icon-check-profile"
          />
        </div>
        <h1 class="verify-title">
          <span v-if="verifyStep === verifyProfileEnums.VERIFY_ACCOUNT">{{
            $t("verify_your_account")
          }}</span>
          <span
            v-else-if="verifyStep === verifyProfileEnums.SUCCESSFULLY_VERIFIED"
            >{{ $t("account_verify_success") }}</span
          >
        </h1>
        <p
          class="verify-subtitle"
          v-if="verifyStep === verifyProfileEnums.VERIFY_ACCOUNT"
        >
          {{ $t("verify_account_warning_text") }}
        </p>
      </div>
      <div class="verify-required-steps-container mobile-padding">
        <div class="verify-required-steps">
          <span class="verify-required-steps-title">{{
            $t("required_steps")
          }}</span>
          <!-- listas com os cadastros que precisa fazer -->
          <ul class="steps-list">
            <li class="item">
              <span class="check" v-if="!checkCompliance" />
              <div v-else>
                <font-awesome-icon
                  icon="fa-solid fa-circle-check"
                  class="icon-successfully-verify-check"
                />
              </div>
              <div class="registration-item">
                <span class="title">{{ $t("investor_form_compliance") }}</span>
                <span class="sub-title">{{
                  $t("investor_form_compliance_text")
                }}</span>
              </div>
            </li>
            <li class="item">
              <span class="check" v-if="!checkComplyCube" />
              <div v-else>
                <font-awesome-icon
                  icon="fa-solid fa-circle-check"
                  class="icon-successfully-verify-check"
                />
              </div>
              <div class="registration-item">
                <span class="title">{{ $t("investor_form_complycube") }}</span>
                <span class="sub-title">{{
                  $t("investor_form_complycube_text")
                }}</span>
              </div>
            </li>
          </ul>
          <span
            v-if="verifyStep === verifyProfileEnums.SUCCESSFULLY_VERIFIED"
            class="complete-profile-success-text"
            >{{ $t("all_right") }}</span
          >
          <div
            class="complete-profile-button-container"
            v-if="verifyStep === verifyProfileEnums.VERIFY_ACCOUNT"
          >
            <v-btn
              class="complete-profile-button"
              color="#1A56DB"
              @click="handleCompleteRegistration"
              data-test="Alpha:Verify:BtnCompleteRegister"
              :loading="complyCubeLoading"
            >
              <span>
                {{ $t("continue") }}
              </span>
              <font-awesome-icon
                icon="fa-solid fa-arrow-right "
                class="complete-profile-button-icon"
              />
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="verify-footer">
      <p class="verify-footer-text">
        {{ $t("read_more_about_1") }}
        <span class="link" @click="openPrivacyPolicy()">
          {{ $t("privacy_policy") }}
        </span>
        {{ $t("read_more_about_2") }}
      </p>
    </div>
    <!-- MODAL DO COMPLYCUBE -->
    <div id="complycube-mount"></div>
  </v-container>
</template>

<script>
import { openPrivacyPolicy } from "@/shared/helpers/openPrivacyPolicyTerm";
import { verifyProfileEnums } from "../../enums/ProfileEnum";
import { handleInvestorRedirectAfterCompleteProfile } from "@/shared/helpers/completeProfile";
import ApiService from "@/services/ApiService";

export default {
  name: "VerifyProfile",
  props: {
    verifyStep: Number,
    checkCompliance: Boolean,
    checkComplyCube: Boolean,
  },
  components: {},
  data() {
    return {
      apiService: new ApiService(),
      openPrivacyPolicy,
      verifyProfileEnums,
      handleInvestorRedirectAfterCompleteProfile,
      token: null,
      complycube: null,
      clientId: null,
      stages: null,
      complyCubeLoading: false,
    };
  },
  created() {
    if (this.verifyStep === this.verifyProfileEnums.SUCCESSFULLY_VERIFIED) {
      this.handleRedirectFinishRegistration();
    }
  },
  methods: {
    handleCompleteRegistration() {
      // PRIMEIRO O CADASTRO DE COMPLIANCE
      if (!this.checkCompliance && !this.checkCompliance) {
        this.$router.push("/profile/complete");
      }
      // APÓS COMPLETAR O CADASTRO DE COMPLIANCE VAMOS FAZER O DO COMPLYCUBE
      else if (this.checkCompliance && !this.checkComplyCube) {
        this.startComplyCubeVerification();
      }
    },
    handleRedirectFinishRegistration() {
      setTimeout(() => {
        this.handleInvestorRedirectAfterCompleteProfile();
      }, [7000]);
    },
    async startComplyCubeVerification() {
      this.complyCubeLoading = true;
      const user = JSON.parse(localStorage.getItem("user"));

      await this.apiService
        .getRequest(`backgroundcheck/get-sdk-token/${user.id}`)
        .then((resp) => {
          this.complyCubeLoading = false;
          this.token = resp.content.token.token;
          this.clientId = resp.content.clientId;
          this.stages = resp.content.checks;
          this.complycube = ComplyCube.mount({
            token: this.token,
            stages: this.stages,
            onComplete: async (data) => {
              data.clientId = this.clientId;
              await this.apiService.postRequest(
                `backgroundcheck/checks-return`,
                data
              );
              this.$emit("handleCompleteComplyCubeRegister");
              this.closeWidget();
            },
            onModalClose: () => {
              this.closeWidget();
            },
          });
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },

    async closeWidget() {
      this.complycube.unmount();
    },
  },
};
</script>

<style scoped>
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile-padding {
  padding: 0px 32px;
}

.verify-title {
  font-size: 24px;
  font-weight: 700;
  font-family: "Source Sans Pro";
  margin-bottom: 24px;
  color: var(--dark);
  text-align: center;
}

.verify-subtitle {
  text-align: center;
  font-size: 16px;
  font-family: "Source Sans Pro";
  color: var(--dark);
}

.verify-title-container {
  margin-bottom: 97px;
  margin-top: 64px;
}

.verify-required-steps-title {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 32px;
  color: var(--dark);
  font-family: "Source Sans Pro";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.verify-required-steps-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.complete-profile-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.verify-required-steps > ul.steps-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.verify-required-steps > ul.steps-list > li.item > span.check {
  width: 16px;
  height: 16px;
  display: block;
  border: 2px solid #9ca3af;
  border-radius: 50%;
  margin-top: 6px;
}

.verify-required-steps > ul.steps-list > li.item {
  display: grid;
  gap: 16px;
  grid-template-columns: 16px 1fr;
}

.registration-item > span.title {
  font-size: 14px !important;
  color: var(--dark);
  display: block;
  font-family: "Source Sans Pro";
}

.registration-item > span.sub-title {
  color: #4b5563;
  font-size: 12px;
  display: block;
  font-family: "Source Sans Pro";
  max-width: 402px;
}

.complete-profile-button {
  border-radius: 8px;
  height: 41px;
  width: 100% !important;
  max-width: 434px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Source Sans Pro";
  margin-top: 32px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.complete-profile-button {
  display: block;
}

.verify-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100% !important;
  margin-top: 32px;
}

.verify-footer-text {
  color: #94a3b8;
  font-size: 14px;
  text-align: center;
}

.verify-footer-text > span.link {
  font-weight: 700;
  text-decoration: underline;
  color: #94a3b8;
  cursor: pointer;
}

.icon-check-profile {
  color: #1c64f2;
  width: 32px;
  height: 25px;
}

.icon-check-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin-bottom: 24px;
}

.icon-successfully-verify-check {
  color: #166534;
  margin-top: 6px;
  font-size: 18px;
}

.complete-profile-success-text {
  display: block;
  text-align: center;
  margin-top: 32px;
  font-size: 14px;
  font-weight: 500;
  color: #1c64f2;
}

.arrow-back {
  color: #94a3b8;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 64px;
  margin-bottom: 8px;
}

.complete-profile-button-icon {
  display: block;
  margin-left: 8px;
}

@media (max-width: 769px) {
  .arrow-back {
    margin-bottom: 0px;
    margin-top: 20px;
    margin-left: 32px;
  }

  .verify-footer {
    padding-left: 32px;
    padding-right: 32px;
  }
}
</style>
